import { Link } from 'react-router-dom'
import './PageMenu.css'

import { langTranslate, state } from '../Lang/Lang'

const PageMenu = () => (
    <div className="rc">
        <div className="nav">
            {/* 
                should be localizated by <Lang /> 
                                       #attention
            */}
            <Link to="/about" className="nav__li">{langTranslate(state.lang, 'MENU-about')}</Link>
            <Link to="/team" className="nav__li">{langTranslate(state.lang, 'MENU-team')}</Link>
            <Link to="/media" className="nav__li">{langTranslate(state.lang, 'MENU-media')}</Link>
            <Link to="/projects" className="nav__li">{langTranslate(state.lang, 'MENU-projects')}</Link>
        </div>
    </div>
)

export default PageMenu