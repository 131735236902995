import { Link } from "react-router-dom"
import PageMenu from "../../components/PageMenu/PageMenu"
import ts from './Team.module.css'
import person from './person.png'
// // import Lang from "../../components/Lang/Lang"
import { Lang, state, langTranslate } from '../../components/Lang/Lang'

import Rights from "../../components/Rights/Rights"

const cardsDetails = [
    {
        'name': 'Name/Nick',
        'nickname': 'Nickname',
        'role': 'Role',
        'link': 'Personal link/Email',
        'mail': 'noreply@dotcontrol.ru',
        'show_name': 1,
        'show_link': 1
    },
    {
        'name': 'Name Surname',
        'nickname': 'kawasaki',
        'role': 'Lead',
        'link': 'ok.ru',
        'mail': 'admin@dotcontrol.ru',
        'show_name': 0,
        'show_link': 0
    },
    {
        'name': 'Stepan Kasatkin',
        'nickname': 'stk',
        'role': 'Web Designer',
        'link': 'vk.com/stk537',
        'mail': 'stk537@dotcontrol.ru',
        'show_name': 1,
        'show_link': 1
    },
    {
        'name': 'Name/Nick',
        'nickname': 'Nickname',
        'role': 'Role',
        'link': 'Personal link/Email',
        'mail': 'noreply@dotcontrol.ru',
        'show_name': 1,
        'show_link': 1
    }
];

const cards = []
cardsDetails.forEach((data) => {
    cards.push(<div className={ts.card}>
        <div className={ts.card__img}>
            <img src={person} alt="person" />
        </div>
        <div className={ts.card__desc}>
            <p>{data.role}</p>
            {/* <p>{langTranslate(state.lang, 'TEAM-roles-' + data.role)}</p> */}
            <p>{data.show_name ? data.name : data.nickname}</p>
            <p>{data.show_link ? data.link : data.mail}</p>
            {/* <p><Link to="/user?={data.nickname}" className={"card_ref " + ts.card_ref}>{langTranslate(state.lang, 'TEAM-link-more')}</Link></p> */}
            <p><Link to="/cp" className={"card_ref " + ts.card_ref}>{langTranslate(state.lang, 'TEAM-link-more')}</Link></p>

        </div>
    </div>)
})

const Team = () => (
    <div className={ts.wrapper}>
        <Lang />
        <div className={ts.lc}>
            <div className={ts.cardwrapper}>
                {cards}
            </div>
        </div>
        <PageMenu />
        <Rights />
    </div>
)

export default Team;