import { Admin, Resource, ListGuesser } from "react-admin"
import jsonServerProvider from 'ra-data-json-server';
import authProvider from "../../components/AuthProvider/AuthProvider";
const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com")

const AdminPage = () => (
    <Admin dataProvider={dataProvider} basename="/admin" authProvider={authProvider} title="AdminPage">
        <Resource name="users" list={ListGuesser}/>
    </Admin>
)

export default AdminPage