import PageMenu from '../../components/PageMenu/PageMenu'
import ms from './Media.module.css'
import tg from './tg.svg'
import vk from './vk.svg'
import git from './git.svg'
// import Lang from '../../components/Lang/Lang'
import { Lang } from '../../components/Lang/Lang'
import Rights from '../../components/Rights/Rights'

const Media = () => (
    <div className={ms.wrapper}>
        <Lang />
        <div className={ms.lc}>
            <div className={ms.logo}>
                <a href="tg://resolve?domain=archangelvkedax" rel="noreferrer" target="_blank">
                    <img src={tg} alt="tg logo" />
                </a>
                <a href="https://vk.com/archangelvkedax" rel="noreferrer" target="_blank">
                    <img src={vk} alt="vk logo" />
                </a>
                <a href="https://github.com/extpankov" rel="noreferrer" target="_blank">
                    <img src={git} alt="git logo" />
                </a>
            </div>
        </div>
        <PageMenu />
        <Rights />
    </div>
)

export default Media