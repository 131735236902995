import as from './About.module.css';
import PageMenu from '../../components/PageMenu/PageMenu';
// import Lang from '../../components/Lang/Lang';
import {Lang, state, langTranslate } from '../../components/Lang/Lang'
import Rights from '../../components/Rights/Rights';

const About = () => (
  <div className={as.wrapper}>
    <Lang />
    <div className={as.lc}>
      {/* <p className={as.p}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam ab officia nostrum saepe veniam, quibusdam, expedita doloremque excepturi quasi voluptates exercitationem? Incidunt consequatur vero fuga in pariatur quia, non impedit?</p> */}
      {/* <p className={as.p}>Corporis delectus beatae nostrum saepe magnam, repellat recusandae, eius illo commodi optio officia odio ex sed aperiam deleniti quibusdam laudantium consectetur aliquid, et possimus. Totam eos odit fugit officiis harum.</p> */}
      {/* <p className={as.p}>Modi quos facere deserunt quo, voluptatum quam recusandae doloremque voluptates nisi, molestias rem, commodi odit! Ipsam quis iste dolorum dignissimos tempora explicabo reprehenderit dolor voluptatum? Repellendus voluptates explicabo quas sequi?</p> */}
      <p className={as.p}>{langTranslate(state.lang, 'ABOUT-info')}</p>
      <h3 className={as.h3}>dotCONTROL<br></br>2022</h3>
    </div>
    <PageMenu />
    <Rights />
  </div>
)

export default About
