// import Lang from '../../components/Lang/Lang'
import {Lang, state, langTranslate} from '../../components/Lang/Lang'
import PageMenu from '../../components/PageMenu/PageMenu'
import Rights from '../../components/Rights/Rights'
import ps from './Projects.module.css'
import sd from './sd.svg'

const Projects = () => (
    <div className={ps.wrapper}>
        <Lang />
        <div className={ps.lc}>
            <div className={ps.lc__container}>
                <div className={ps.lc__lc}>
                    <div className={ps.lc__logo}>
                        <img src={sd} alt="s&d logo" />
                    </div>
                    <div className={ps.lc__date}>
                        <div className={ps.lc__datename}>{langTranslate(state.lang, 'PROJECTS-project-1-founded')}</div>
                        <div className={ps.lc__datedate}>19.10.2017</div>
                    </div>
                </div>

                <h1 className={ps.lc__name}>{langTranslate(state.lang, 'PROJECTS-project-1-name')}</h1>
                
                <a href="https://vk.com/sd_scripts" className={ps.lc__about} target="_blank" rel='noreferrer'>{langTranslate(state.lang, 'PROJECTS-project-1-know_more')}</a>
                <p className={ps.lc__desc}>«{langTranslate(state.lang, 'PROJECTS-project-1-description')}</p>
                <div className={ps.lc__note}>{langTranslate(state.lang, 'PROJECTS-project-1-activity')}</div>
            </div>
        </div>
        <PageMenu />
        <Rights />
    </div>
)

export default Projects