import { Routes, Route } from "react-router-dom";


import Main from "./pages/Main/Main";
import About from './pages/About/About'
import Team from "./pages/Team/Team";
import Media from "./pages/Media/Media";
import Projects from "./pages/Projects/Projects";
import NotFoundPage from "./pages/404/404";
import AdminPage from "./pages/Admin/Admin";
import Menu from "./components/Menu/Menu";

import { useEffect } from "react";



export default function App() {
    useEffect(() => {
        document.title = "DotControl";
      }, []);
    return(
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/media" element={<Media />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/admin/*" element={<AdminPage />} />
        {/* <Route path="/cp" element={<cp />} /> */}
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
    )
}