import ms from './Main.module.css';
import logo from './svg/dotlogo.svg'
import dots from './svg/ellipsis-h-solid.svg'

import { Link } from 'react-router-dom';

const Main = () => (
  <div className={ms.wrapper}>
  <div className={ms.lc}>
      <div className={ms.logo}>
          <img src={logo} alt="dotcontrol_logo" className={ms.logo__pic}></img>
      </div>
      <span className={ms.line}></span>
      <p text="swipe"></p>
  </div>
  <div className={ms.rc}>
      <h1 className={ms.h1}>UNDER DEVELOPMENT</h1>
      <p className={ms.p}>The site is under development. We don't know if it will ever be finished.<br />Сайт находится в разработке. Мы не можем сказать, будет ли он когда либо закочен.</p>
      <h2 className={ms.h2}>copyright 2022</h2>
  </div>
  <section className={ms.menu__body} hidden="hidden">
      <div className={ms.menu__header}>
          <p text="menu_nav"></p>
          <button title="Close">
              <img src="src/svg/times-solid.svg" alt="krestik"></img>
          </button>
      </div>
      <div className={ms.menu__links}>
          <a href="#0">О нас</a>
          <a href="#0">Команда</a>
          <a href="#0">Соцсети</a>
          <a href="#0">Проекты</a>
          <a href="#0">development</a>
      </div>
      <div className={ms.menu__contact}>
          {/* <a href="#language" onClick="changeLagnuage()"> */}
          <a href="#language">
              <img src="./src/svg/language.svg" alt="language switcher"></img>
              <span text="menu_language"></span>
          </a>
      </div>
  </section>
  <div className={ms.menu__overlay} hidden="hidden"></div>
  {/* <div className={ms.menu_btn}>
      <div className={ms.menu_btn_ul}>ru</div>
      <div className={ms.menu_btn_line}></div>
      <Link to="/menu" className={ms.menu_btn_ul}>menu</Link>
  </div> */}
  <div className={ms.circle__menu} onClick={showMenu}>
      <img src={dots} alt="asd"></img>
  </div>
  <div className={ms.nav}>
    {/* <Link to="/about">aboutpage</Link>
    <Link to="/team">teampage</Link>
    <Link to="/media">mediapage</Link>
    <Link to="/projects">projectspage</Link> */}
    {/* <Link to="/admin">adminpage</Link> */}
    {/* <Link to="/asd">404</Link> */}
  </div>
  {/* <Routes>
      <Route path="/about" element={<About />} />
  </Routes> */}
</div>
)

// const close = document.querySelector('.menu__header button');

const showMenu = () => {
  console.log(1);
  document.querySelector('.circle_menu').setAttribute('hidden', '');
  document.querySelector('.menu__body').removeAttribute('hidden');
  document.querySelector('.menu__overlay').removeAttribute('hidden');
}

export default Main

// function hideMenu() {
//   document.querySelector('.menu__body').setAttribute('hidden', '');
//   document.querySelector('.menu__overlay').setAttribute('hidden', '');
//   document.querySelector('.circle_menu').removeAttribute('hidden');
// };

// button.addEventListener('click', showMenu);
// close.addEventListener('click', hideMenu);
// overlay.addEventListener('click', hideMenu);