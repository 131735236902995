import { Link } from 'react-router-dom'
import ms from './Menu.module.css'
import logo from './menu.svg'

import { state, langTranslate} from '../../components/Lang/Lang'

const Menu = () => (
    <div className={ms.wrapper}>
        <div className={ms.logo}>
            <img src={logo} alt="logo" />
        </div>
        <div className={ms.nav}>
            
            <Link to="/about" className={"nav__li " + ms.nav__li}>{langTranslate(state.lang, 'MENU-about')}</Link>
            <Link to="/team" className={"nav__li " + ms.nav__li}>{langTranslate(state.lang, 'MENU-team')}</Link>
            <Link to="/media" className={"nav__li " + ms.nav__li}>{langTranslate(state.lang, 'MENU-media')}</Link>
            <Link to="/projects" className={"nav__li " + ms.nav__li}>{langTranslate(state.lang, 'MENU-projects')}</Link>
            
        </div>
    </div>
)

export default Menu