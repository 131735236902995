import ms from '../../pages/Main/Main.module.css'
import { Link } from 'react-router-dom';

/* 
    translations base with:
        [language] => {keywords: localization' strings} 
*/
let translations = {
    'en': {
        'LANG-btn-goback': 'main',
        'LANG-state': 'ru',

        'ABOUT-info': 'Ubi potest ego adepto eam? Multa sunt variantes Lorem Ipsum dolor sit amet, sed plerique ex illis non semper accepta affectiones, ut pro exemplo, hujusmodi interserit, aut verba, ut ne minima quidem similis latine.Si vos postulo Lorem Ipsum dolor sit amet enim gravis project, vos forsit non volo aliqua iocus occulta in medium paragraph.Etiam, et omnia alia bene- notum Lorem Ipsum dolor generantibus eadem text, quae sunt simpliciter repetere, donec ad optatum volumine.Hoc facit generator obtulit hic solus verus Lorem Ipsum generator.It uses a dictionary of plus quam 200 Latin words, ut a paro of sententiam exempla.Ut a praecessi, in generatur Lorem Ipsum dolor spectat probabilis, non habet repetita paragraphs vel "impossibile" verba.',

        'PROJECTS-project-1-name': 'S&D Scripts',
        'PROJECTS-project-1-description': '«S&D Scripts» is a small team engaged in the development of free small- and large-scale projects for the "San Andreas Multiplayer (SA-MP)" multiplayer in the programming languages Lua, AHK, C++. There are paid products aimed at a narrow audience and software development services.',
        'PROJECTS-project-1-activity': 'since 01.01.2022, the team\'s activities have been suspended indefinitely',
        'PROJECTS-project-1-know_more': 'more..',
        'PROJECTS-project-1-founded': 'Date of foundation',
        
/*         'TEAM-role-Role': '',
        'TEAM-role-Web Designer': '',
        'TEAM-role-Lead': '', */
        'TEAM-link-more': 'more...',
        
        'MENU-about': 'About',
        'MENU-team': 'Team',
        'MENU-media': 'Media',
        'MENU-projects': 'Projects',

        "NFP-unavailable": "temporarily unavailable",
        "NFP-page": "page",

        'RIGHTS-reserved': 'all rights reserved',
        
    },
    'ru': {
        'LANG-btn-goback': 'главная',
        'LANG-state': 'en',

        'ABOUT-info': 'Где его взять? Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь.Если вам нужен Lorem Ipsum для серьёзного проекта, вы наверняка не хотите какой- нибудь шутки, скрытой в середине абзаца.Также все другие известные генераторы Lorem Ipsum используют один и тот же текст, который они просто повторяют, пока не достигнут нужный объём.Это делает предлагаемый здесь генератор единственным настоящим Lorem Ipsum генератором.Он использует словарь из более чем 200 латинских слов, а также набор моделей предложений.В результате сгенерированный Lorem Ipsum выглядит правдоподобно, не имеет повторяющихся абзацей или "невозможных" слов.',

        'PROJECTS-project-1-name': 'S&D Scripts',
        'PROJECTS-project-1-description': '«S&D Scripts» - небольшая команда, занимающаяся разработкой бесплатных мало- и крупномасштабных проектов для мультиплеера «San Andreas Multiplayer (SA-MP)» на языках программирования Lua, AHK, C++. Существуют платные продукты, ориентированные на узкую аудиторию и услуги по разработке программного обеспечения.',
        'PROJECTS-project-1-activity': 'с 01.01.2022 деятельность команды бессрочно приостановлена',
        'PROJECTS-project-1-know_more': 'подробнее..',
        'PROJECTS-project-1-founded': 'Дата основания',

        'TEAM-link-more': 'подробнее...',

        'MENU-about': 'О нас',
        'MENU-team': 'Команда',
        'MENU-media': 'Соцсети',
        'MENU-projects': 'Проекты',

        "NFP-unavailable": "временно недоступна",
        "NFP-page": "страница",

        'RIGHTS-reserved': 'все права защищены',
    }
}

// choosing correct localization' keywords = [text] for language with code = [lang]
let langTranslate = (lang, text) => {
    return translations[lang][text] ? translations[lang][text] : text;
}

// language switcher by button 
let langChange = () => {
    state.lang = langTranslate(state.lang, 'LANG-state')
    this.setState({language: state.lang})
}

/*
    base language code
    (
        *should be upgraded to 
            [sessionStore.User.selectedLanguage]
                            or
            SQLBase->table_user.settings.ThemeLanguage
    )
*/
let state = {
    lang: 'en',
}

const Lang = () => (

    // #attention (full-line menubar)
    <div className={ms.menu_btn}>
        
        <div className={ms.menu_btn_ul} onClick={() => langChange()}>{langTranslate(state.lang, 'LANG-state')}</div>
    {/* 
    des:<div className={ms.menu_btn_ul} onClick={        ...       }>                    ru                   </div>
                                                                                      (on cond. state.lang === 'en')
    */}
        
        
        <div className={ms.menu_btn_line}></div>

        <Link to="/" className={ms.menu_btn_ul}>{langTranslate(state.lang, 'LANG-btn-goback')}</Link>
    {/*
    des:<Link to="/" className={ms.menu_btn_ul}>                          menu                          </Link>
                                                                                 (on cond. state.lang === 'en')
    */}

    </div>
    
)

export {Lang, langTranslate, state}