import nfps from './NotFoundPage.module.css'
import PageMenu from '../../components/PageMenu/PageMenu';
import { Link } from 'react-router-dom';
// import Lang from '../../components/Lang/Lang';
import { Lang, state, langTranslate } from '../../components/Lang/Lang'

import Rights from '../../components/Rights/Rights';

const NotFoundPage = () => (
    <div className={nfps.wrapper}>
        <Link to="/" className={nfps.warning}>return back</Link>
        <Lang />
        <div className={nfps.lc}>{langTranslate(state.lang, "NFP-page")}<br></br>{langTranslate(state.lang, "NFP-unavailable")}</div>
        <PageMenu />
        <Rights />
    </div>
)

export default NotFoundPage